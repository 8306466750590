<template>
  <div id="attract">
    <CommonForm :form="searchForm"
                :formLabel="searchformLabel"
                ref="select"
                class="filtrateInput">
      <el-button type="primary"
                 @click="searchHotelProductToAudit(searchForm)">搜 索</el-button>
      <el-button>导出</el-button>
      <el-button type="text">查看已导出列表</el-button>
      <el-button type="text"
                 @click="resetFilter(searchForm)">重置筛选条件</el-button>
    </CommonForm>
    <el-table :data="tableData"
              border
              style="width: 100%">
      <el-table-column prop="id"
                       label="编号"
                       width="180">
      </el-table-column>
      <el-table-column prop="create_time"
                       label="日期"
                       width="180">
      </el-table-column>
      <el-table-column prop="nickname"
                       label="姓名"
                       width="180">
      </el-table-column>
      <el-table-column prop="phone"
                       label="电话">
      </el-table-column>
      <el-table-column prop="address"
                       label="地址">
      </el-table-column>
      <el-table-column prop="remarks"
                       label="留言">
      </el-table-column>
      <el-table-column prop="status"
                       label="状态">
      </el-table-column>
    </el-table>
    <div style="display: flex;margin-top: 20px;flex-direction: row-reverse">
      <el-pagination @size-change="handleSizeChange"
                     @current-change="handleCurrentChange"
                     :current-page="currentPage4"
                     :page-sizes="[100, 200, 300, 400]"
                     :page-size="100"
                     layout="total, sizes, prev, pager, next, jumper"
                     :total="400">
      </el-pagination>
    </div>

  </div>
</template>

<script>
import CommonForm from 'components/CommonForm.vue'
import { getAttractInfo } from 'api/productManage'
export default {
  components: {
    CommonForm
  },
  data () {
    return {
      currentPage4: 4,
      searchForm: {
        nickname: '',
        phone: '',
        time: [],
        status: ''
      },
      tableData: null,
      searchformLabel: [
        {
          model: 'nickname',
          label: '客户昵称',
          width: '304px'
        },
        {
          model: 'phone',
          label: '客户手机号',
          width: '304px'
        },
        {
          model: 'time',
          label: '可选择日期',
          width2: '200px',
          type: 'datePicker'
        },
        {
          model: 'status',
          label: '状态',
          type: 'select',
          width: '250px',
          placeholder: '请选择',
          options: [
            { value: 0, label: '待联系' },
            { value: 1, label: '待联系' },
            { value: 2, label: '有合作意向' },
            { value: 3, label: '无合作意向' }
          ]
        },
      ]
    }
  },
  created () {
    this.test()
  },

  methods: {
    handleSizeChange (val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange (val) {
      console.log(`当前页: ${val}`);
    },
    async test () {
      console.log(123);
      await getAttractInfo().then(res => {
        this.tableData = res.data.data.list
      })
      console.log(this.tableData);
    }
  }
}
</script>

<style>
</style>